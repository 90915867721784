<template>
    <v-container fluid>
        <v-form @submit.prevent="saveCertificate">
            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                <v-card flat>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-row class="flex-nowrap">
                                    <v-col cols="1" style="max-width: 80px">
                                        <v-btn :to="{name: 'certificate'}" class="back-btn" link large>
                                            <v-icon>mdi-less-than</v-icon>
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="11">
                                        <v-subheader class="headline">{{ heading }}</v-subheader>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="6">
                                <v-tabs v-model="tab" background-color="transparent"
                                        :right="$vuetify.breakpoint.mdAndUp"
                                        :grow="$vuetify.breakpoint.smAndDown"
                                        color="deep-purple accent-4" @change="setLanguage" class="pb-4">
                                    <v-tab ref="language" v-for="language in languages" :key="language">
                                        <span :class="exist_translations[language] ? '' : 'red--text '">
                                            {{ language }}
                                        </span>
                                    </v-tab>
                                </v-tabs>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="12" sm="3" md="2" class="text-center">
                                <make-photo v-model="photo" :aspect-ratio="(1/1)"/>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="9" md="10">
                                <v-row>
                                    <v-col class="py-0" cols="12" sm="12">
                                        <ValidationProvider ref="title" rules="required|min:3|max:255"
                                                            v-slot="{ errors, valid }">
                                            <v-text-field v-model="title" type="text"
                                                          :error="!valid" :error-messages="errors"
                                                          :disabled="loading" prepend-icon="mdi-ticket-percent-outline"
                                                          :label="$t('certificate_name')" color="primary"
                                                          clearable>
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>

                                    <v-col class="py-0" cols="12" sm="6">
                                        <v-dialog ref="expirationDialogFrom" v-model="expirationDialogFrom" width="290px">
                                            <v-date-picker ref="pickerExpirationFrom" v-model="expiration_from"
                                                           :max="expiration_to ? $moment(expiration_to).format('YYYY-MM-DD') : $moment().add(10, 'y').format('YYYY-MM-DD')"
                                                           :locale="lang" @change="expirationDialogFrom = false"></v-date-picker>
                                        </v-dialog>
                                        <ValidationProvider ref="expiration_from" rules="required|date_format"
                                                            v-slot="{ errors, valid }">
                                            <v-text-field v-model="expiration_from" :error-messages="errors"
                                                          :disabled="loading" :label="$t('date_from')"
                                                          :error="!valid"
                                                          prepend-icon="mdi-calendar" color="primary" readonly
                                                          @click.stop="expirationDialogFrom = !expirationDialogFrom"
                                                          clearable @click:clear="expiration_from = null"></v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="py-0" cols="12" sm="6">
                                        <v-dialog ref="expirationDialogTo" v-model="expirationDialogTo" width="290px">
                                            <v-date-picker ref="pickerExpirationTo" v-model="expiration_to"
                                                           :min="expiration_from ? $moment(expiration_from).format('YYYY-MM-DD') : $moment().subtract(10, 'y').format('YYYY-MM-DD')"
                                                           :locale="lang" @change="expirationDialogTo = false"></v-date-picker>
                                        </v-dialog>
                                        <ValidationProvider ref="expiration_to" rules="required|date_format"
                                                            v-slot="{ errors, valid }">
                                            <v-text-field v-model="expiration_to" :error-messages="errors" :disabled="loading"
                                                          :label="$t('date_to')" prepend-icon="mdi-calendar"
                                                          :error="!valid"
                                                          color="primary" readonly clearable
                                                          @click.stop="expirationDialogTo = !expirationDialogTo"
                                                          @click:clear="expiration_to = null"></v-text-field>
                                        </ValidationProvider>
                                    </v-col>



                                    <v-col class="py-0" cols="12" sm="6">
                                        <ValidationProvider ref="type_company" rules="required|min:1"
                                                            v-slot="{ errors, valid }">
                                            <v-select v-model="type_company" :disabled="loadingTypeCompanies"
                                                      :items="type_companyItems" :error-messages="errors"
                                                      prepend-icon="mdi-home-variant-outline" :error="!valid"
                                                      color="primary" item-text="name" item-value="id"
                                                      @change="resetTypeCompany"
                                                      :label="$t('type_company')" clearable></v-select>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="py-0" cols="6" v-if="type_company === 1 ? true : false">
                                        <ValidationProvider ref="shop" rules="required|min:1"
                                                            v-slot="{ errors, valid }">
                                            <v-autocomplete v-model="shop" :items="shopItems"
                                                            :error-messages="errors" :error="!valid"
                                                            :search-input.sync="shopSearching"
                                                            item-text="name" item-value="id"
                                                            prepend-icon="mdi-home-flood"
                                                            append-icon="mdi-location-enter"
                                                            :loading="loadingShops" :disabled="loading"
                                                            :no-data-text="shopSearching ? $t('nothing_found_by',{'search': shopSearching}) : $t('nothing_found_name')"
                                                            :label="$t('shop')"
                                                            @click:clear="shopItems=[]"
                                                            @click="clearShops"
                                                            @click:append="redirectToShop(shop)"
                                                            color="primary" clearable autocomplete="off">
                                                <template v-slot:item="{ attrs, item, parent, selected }">
                                            <span class="search-list">
                                                <span v-html="item.name"></span>
                                                <span class="ml-2 hidden-is-empty"
                                                      v-text="item.address_legal"></span>
                                            </span>
                                                </template>
                                                <template v-slot:selection="{ attrs, item, parent, selected }">
                                            <span class="search-list">
                                                <span v-html="item.name"></span>
                                                <span class="ml-2 hidden-is-empty"
                                                      v-text="item.address_legal"></span>
                                            </span>
                                                </template>
                                            </v-autocomplete>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="py-0" cols="12" sm="6" v-if="type_company === 2 ? true : false">
                                        <ValidationProvider ref="coffee_house" rules="required|min:1"
                                                            v-slot="{ errors, valid }">
                                            <v-select v-model="coffee_house" :disabled="loadingCoffeeHouses"
                                                      :items="coffee_houseItems" :error-messages="errors"
                                                      prepend-icon="mdi-coffee-maker" :error="!valid"
                                                      color="primary" item-text="name" item-value="id"
                                                      :label="$t('coffee_house')" clearable></v-select>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="py-0" cols="12" sm="6" v-if="type_company === 3 ? true : false">
                                        <ValidationProvider ref="restaurant" rules="required|min:1"
                                                            v-slot="{ errors, valid }">
                                            <v-select v-model="restaurant" :disabled="loadingRestaurants"
                                                      :items="restaurantItems" :error-messages="errors"
                                                      prepend-icon="mdi-food-variant" :error="!valid"
                                                      color="primary" item-text="name" item-value="id"
                                                      :label="$t('restaurant')" clearable></v-select>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="py-0" cols="12" sm="6" v-if="type_company === 4 ? true : false">
                                        <ValidationProvider ref="fitness" rules="required|min:1"
                                                            v-slot="{ errors, valid }">
                                            <v-select v-model="fitness" :disabled="loadingFitness"
                                                      :items="fitnessItems" :error-messages="errors"
                                                      prepend-icon="mdi-dumbbell" :error="!valid"
                                                      color="primary" item-text="name" item-value="id"
                                                      :label="$t('fitness')" clearable></v-select>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="py-0" cols="12" sm="6" v-if="type_company === 5 ? true : false">
                                        <ValidationProvider ref="cinema" rules="required|min:1"
                                                            v-slot="{ errors, valid }">
                                            <v-select v-model="cinema" :disabled="loadingCinema"
                                                      :items="cinemaItems" :error-messages="errors"
                                                      prepend-icon="mdi-camcorder" :error="!valid"
                                                      color="primary" item-text="name" item-value="id"
                                                      :label="$t('cinema')" clearable></v-select>
                                        </ValidationProvider>
                                    </v-col>

                                    <v-col class="py-0" cols="12" sm="6" v-if="type_company === 6 ? true : false">
                                        <ValidationProvider ref="park" rules="required|min:1"
                                                            v-slot="{ errors, valid }">
                                            <v-select v-model="park" :disabled="loadingPark"
                                                      :items="parkItems" :error-messages="errors"
                                                      prepend-icon="mdi-candycane" :error="!valid"
                                                      color="primary" item-text="name" item-value="id"
                                                      :label="$t('park')" clearable></v-select>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="py-0" cols="12" sm="6" v-if="type_company === 7 ? true : false">
                                        <ValidationProvider ref="shopping_center_service" rules="min:1"
                                                            v-slot="{ errors, valid }">
                                            <v-select v-model="shopping_center_service" :disabled="loadingShoppingCenterService"
                                                      :items="shopping_center_serviceItems" :error-messages="errors"
                                                      prepend-icon="mdi-home-outline" :error="!valid"
                                                      color="primary" item-text="name" item-value="id"
                                                      :label="$t('shopping_center_service')" clearable></v-select>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="py-0" cols="12" sm="6" v-if="type_company === 8 ? true : false">
                                        <ValidationProvider ref="hotel" rules="required|min:1"
                                                            v-slot="{ errors, valid }">
                                            <v-select v-model="hotel" :disabled="loadingHotel"
                                                      :items="hotelItems" :error-messages="errors"
                                                      prepend-icon="mdi-home-variant-outline" :error="!valid"
                                                      color="primary" item-text="name" item-value="id"
                                                      :label="$t('hotel')" clearable></v-select>
                                        </ValidationProvider>
                                    </v-col>



                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="py-0" cols="12" sm="12" v-if="type_company">
                                <ValidationProvider ref="type_certificate" rules="required|min:1"
                                                    v-slot="{ errors, valid }">
                                    <v-select v-model="type_certificate" :disabled="loadingTypeCertificates"
                                              :items="typeCertificateItems" :error-messages="errors"
                                              prepend-icon="mdi-certificate-outline" :error="!valid"
                                              color="primary" item-text="name" item-value="id"
                                              return-object
                                              @change="setTypeCertificate(false)"
                                              :label="$t('type_certificate')" clearable></v-select>
                                </ValidationProvider>
                            </v-col>



                            <v-col class="py-0" cols="12" :sm="cashback_percent_boolean ? 6 : (get_product_boolean || buy_product_boolean ? 6 : 12)" v-if="amount_boolean">
                                <ValidationProvider ref="amount" name="amount"
                                                    rules="required|min_value:0|max_value:999999"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="amount" type="text" :error="!valid"
                                                  :error-messages="errors" :disabled="loading"
                                                  prepend-icon="mdi-sack"
                                                  :label="$t('amount')"
                                                  color="primary" clearable>
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" :sm="amount_boolean ? 6 :  (get_product_boolean || buy_product_boolean ? 6 : 12)" v-if="cashback_percent_boolean">
                                <ValidationProvider ref="cashback_percent" name="cashback_percent"
                                                    rules="required|min_value:0|max_value:100"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="cashback_percent" type="text" :error="!valid"
                                                  :error-messages="errors" :disabled="loading"
                                                  append-icon="mdi-percent-outline mdi-18px"
                                                  prepend-icon="mdi-sack-percent"
                                                  :label="$t('cashback_percent')"
                                                  color="primary" clearable>
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>


                            <v-col class="py-0" cols="12" :sm="get_product_boolean ? 6 :  (cashback_percent_boolean || amount_boolean ? 6 : 12)"  v-if="(shop && buy_product_boolean)">
                                <ValidationProvider ref="buy_product" rules="required|min:1" v-slot="{ errors, valid }">
                                    <v-autocomplete v-model="buy_product" :items="buyProductItems" :error="!valid"
                                                    :error-messages="errors" :search-input.sync="buyProductSearching"
                                                    item-text="name" item-value="id" prepend-icon="mdi-shopping"
                                                    :loading="loadingBuyProduct" :disabled="loading"
                                                    @click="clearBuyProduct"
                                                    :no-data-text="buyProductSearching ? $t('nothing_found_by',{'search': buyProductSearching}) : $t('nothing_found_product_name')"
                                                    :label="$t('buy_product')" @click:clear="buyProductItems=[]"
                                                    color="primary" return-object clearable autocomplete="off">
                                    </v-autocomplete>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" :sm="buy_product_boolean ? 6 : (cashback_percent_boolean || amount_boolean ? 6 : 12)" v-if="(shop && get_product_boolean)">
                                <ValidationProvider ref="get_product" rules="required|min:1" v-slot="{ errors, valid }">
                                    <v-autocomplete v-model="get_product" :items="getProductItems" :error="!valid"
                                                    :error-messages="errors" :search-input.sync="getProductSearching"
                                                    item-text="name" item-value="id" prepend-icon="mdi-shopping"
                                                    :loading="loadingGetProduct" :disabled="loading"
                                                    @click="clearGetProduct"
                                                    :no-data-text="getProductSearching ? $t('nothing_found_by',{'search': getProductSearching}) : $t('nothing_found_product_name')"
                                                    :label="$t('get_product')" @click:clear="getProductItems=[]"
                                                    color="primary" return-object clearable autocomplete="off">
                                    </v-autocomplete>
                                </ValidationProvider>
                            </v-col>


                            <v-col cols="12">
                                <ValidationProvider ref="short_description" rules="required|min:3|max:65535"
                                                    v-slot="{ errors, valid }">
                                    <v-textarea v-model="short_description" type="text" :error-messages="errors"
                                                :disabled="loading" :label="$t('short_description')" rows="5"
                                                :error="!valid"
                                                color="primary" auto-grow clearable outlined>
                                    </v-textarea>
                                </ValidationProvider>
                            </v-col>

                            <v-col cols="12">
                                <v-subheader class="mb-2 font-weight-medium">
                                    {{ $t('description') }}
                                </v-subheader>
                                <ValidationProvider ref="description"
                                                    rules="required|min:3|max:65535"
                                                    v-slot="{ errors, valid }">
                                    <tinymce-editor id="description" v-model="description"
                                                    :init="tinymceInit"></tinymce-editor>
                                    <div v-show="!valid" class="mt-2" style="color: red">
                                        {{ errors[0] ? errors[0] : $t('description_is_not_filled_out') }}
                                    </div>
                                </ValidationProvider>
                            </v-col>

                            <v-col class="pt-0" cols="12">
                                <v-expansion-panels :disabled="loading">
                                    <v-expansion-panel>
                                        <v-expansion-panel-header class="primary white--text">
                                            {{ $t('photo') }}
                                            <template v-slot:actions>
                                                <v-icon color="white">$expand</v-icon>
                                            </template>
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <media-gallery v-model="images"/>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col class="py-0 d-flex justify-start" cols="12" sm="4">
                                <v-switch v-model="deleted"
                                          :disabled="loading || !can(['administrator', 'manager'])"
                                          :readonly="!can(['administrator', 'manager'])"
                                          :label="deleted ? $t('deleted') : $t('remove_certificate')"
                                          @change="deleted ? active = false : active" color="red" hide-details></v-switch>
                            </v-col>
                            <v-col class="py-0 d-flex justify-center" cols="12" sm="4">
                                <v-switch v-model="main"
                                          :disabled="loading"
                                          :label="$t('main_certificate')"
                                          color="primary" hide-details></v-switch>
                            </v-col>
                            <v-col class="py-0 d-flex justify-start justify-sm-end" cols="12" sm="4">
                                <v-switch v-model="active"
                                          :disabled="loading || deleted || !can(['administrator', 'manager'])"
                                          :readonly="!can(['administrator', 'manager'])"
                                          :label="$t('active')"
                                          color="primary" hide-details></v-switch>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="pt-8">
                        <v-progress-linear v-model="progress" :active="loading" class="mx-2"></v-progress-linear>
                        <v-spacer></v-spacer>
                        <v-btn type="submit" :disabled="invalid || loading"
                               :loading="loading" color="primary">
                            {{ $t('save') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </ValidationObserver>
        </v-form>
    </v-container>
</template>
<script>

import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {mapGetters} from "vuex";
import debounce from "lodash/debounce";
import TinymceEditor from "@tinymce/tinymce-vue";
import MakePhoto from "../components/MakePhoto";
import MediaGallery from "../components/MediaGallery";

export default {
    name: 'CertificateForm',
    components: {
        MediaGallery,
        MakePhoto,
        ValidationProvider,
        ValidationObserver,
        TinymceEditor
    },
    inject: ['forceRerender'],
    data() {
        return {
            progress: 0,
            create: false,
            id: null,
            title: null,
            short_description: null,
            description: null,
            shop: null,
            shopItems: [],
            shopSearching: null,
            loadingShops: false,
            coffee_house: null,
            coffee_houseItems: [],
            loadingCoffeeHouses: false,
            restaurant: null,
            restaurantItems: [],
            loadingRestaurants: false,

            fitness: null,
            fitnessItems: [],
            loadingFitness:false,

            cinema: null,
            cinemaItems: [],
            loadingCinema:false,
            park: null,
            parkItems: [],
            loadingPark:false,
            hotel: null,
            hotelItems: [],
            loadingHotel:false,
            shopping_center_service: null,
            shopping_center_serviceItems: [],
            loadingShoppingCenterService:false,



            buy_product: null,
            buyProductItems: [],
            buyProductSearching: null,
            loadingBuyProduct: false,
            buy_product_boolean: false,

            get_product: null,
            getProductItems: [],
            getProductSearching: null,
            loadingGetProduct: false,
            get_product_boolean: false,

            cashback_percent: null,
            cashback_percent_boolean: false,
            amount: null,
            amount_boolean: false,
            active: false,
            photo: null,
            images: [],
            expirationDialogFrom: false,
            expiration_from: null,
            expiration_to: null,
            expirationDialogTo: false,
            deleted: false,
            loading: false,
            errors: {},
            loadingTypeCompanies: false,
            type_companyItems: [],
            type_company: null,
            loadingTypeCertificates: false,
            type_certificate: null,
            typeCertificateItems: [],
            exist_translations: {},
            all_translations: true,
            tabActive: 0,
            heading: null,
            tab: 0,
            main: true,





        }
    },
    computed: {
        ...mapGetters(['listLanguages', 'lang', 'defaultLogo']),
        languages() {
            return this.listLanguages
        },
        tinymceInit() {
            return this.tinymceInitDefault()
        }
    },
    watch: {
        shopSearching: debounce(function (val) {
            if (val && !this.shop) {
                this.getShops(val)
            }
        }, 500),
        type_company() {
            switch (this.type_company){
                case 2 : this.getCoffeeHouses(); break;
                case 3 : this.getRestaurants(); break;
                case 4 : this.getFitness(); break;
                case 5 : this.getCinema(); break;
                case 6 : this.getPark(); break;
                case 7 : this.getShoppingCenterService(); break;
                case 8 : this.getHotel(); break;
            }
            if (this.type_company) {
                this.getTypeCertificates(this.type_company)
            }
        },
        buyProductSearching: debounce(function (val) {
            if (val && !this.buy_product) {
                this.buyProducts(val)
            }
        }, 500),
        getProductSearching: debounce(function (val) {
            if (val && !this.get_product) {
                this.getProducts(val)
            }
        }, 500),
    },
    mounted() {
        this.language = this.languages[this.tab]
        this.loading = true
        this.getTypeCompanies()
        this.checkCreate()
    },
    methods: {
        checkCreate() {
            if (this.$route.name === "certificate.create") {
                this.heading = this.$t('certificate_creation')
                this.loading = false
            } else {
                this.heading = this.$t('certificate_editing')
                if (this.$route.params.id) {
                    this.getCertificate()
                }
            }
        },
        setLanguage(val) {
            this.language = this.languages[val]
            this.checkCreate()
        },
        clearBuyProduct() {
            if (!this.buy_product) {
                this.buyProductItems = []
            }
        },
        async buyProducts(str) {
            if (str) {
                this.loadingBuyProduct = true
                let params = {};
                params.filter = 'search'
                if (str !== 'undefined') {
                    params.product = str;
                }
                if(this.shop){
                    params.shop = this.shop;
                }
                await this.$http
                    .get("admin/product", {
                        params: params,
                    })
                    .then(res => {
                        this.buyProductItems = res.body.data;
                    })
                    .catch(err => {
                        this.buyProductItems = []
                        this.$toastr.error(this.$t('failed_to_get_list_products'))
                    })
                    .finally(end => {
                        this.loadingBuyProduct = false
                    })
            }
        },
        clearGetProduct() {
            if (!this.get_product) {
                this.getProductItems = []
            }
        },
        async getProducts(str) {
            if (str) {
                this.loadingGetProduct = true
                let params = {};
                params.filter = 'search'
                if (str !== 'undefined') {
                    params.product = str;
                }
                if(this.shop){
                    params.shop = this.shop;
                }
                await this.$http
                    .get("admin/product", {
                        params: params,
                    })
                    .then(res => {
                        this.getProductItems = res.body.data;
                    })
                    .catch(err => {
                        this.getProductItems = []
                        this.$toastr.error(this.$t('failed_to_get_list_products'))
                    })
                    .finally(end => {
                        this.loadingGetProduct = false
                    })
            }
        },

        resetTypeCompany() {
            this.type_certificate = null
            this.shop = null
            this.coffee_house = null
            this.restaurant = null
            this.fitness = null
            this.cinema = null
            this.park = null
            this.hotel = null
            this.cashback_percent_boolean = false
            this.amount_boolean = false
            this.buy_product_boolean = false
            this.get_product_boolean = false
        },
        async getTypeCompanies() {
            this.loadingTypeCompanies = true
            let params = {};
            params.filter = 'all';
            await this.$http
                .get(`admin/type_company`, {
                    params: params,
                })
                .then(res => {
                    this.type_companyItems = res.body.data
                })
                .catch(err => {
                    this.type_companyItems = [];
                    this.$toastr.error(this.$t('failed_to_get_list_type_companies'))
                })
                .finally(end => {
                    this.loadingTypeCompanies = false
                });
        },
        async getTypeCertificates(type_company) {
            this.loadingTypeCertificates = true
            let params = {};
            params.filter = 'all'
            params.type_company = type_company
            await this.$http
                .get(`admin/type_certificate`, {
                    params: params,
                })
                .then(res => {
                    this.typeCertificateItems = res.body.data;
                })
                .catch(err => {
                    this.typeCertificateItems = [];
                    this.$toastr.error(this.$t('failed_to_get_list_certificates'))
                })
                .finally(end => {
                    this.loadingTypeCertificates = false
                })
        },
        setTypeCertificate(edit){
            if(edit !== true){
                this.cashback_percent = null;
                this.amount = null;
                this.buy_product = null;
                this.get_product = null;
            }
            if(this.type_certificate.cashback){
                this.cashback_percent_boolean = true
            }
            else {
                this.cashback_percent_boolean = false
            }
            if(this.type_certificate.amount){
                this.amount_boolean = true
            }
            else {
                this.amount_boolean = false
            }

            if(this.type_certificate.buy_product){
                this.buy_product_boolean = true
            }
            else {
                this.buy_product_boolean = false
            }
            if(this.type_certificate.get_product){
                this.get_product_boolean = true
            }
            else {
                this.get_product_boolean = false
            }
        },
        clearShops() {
            if (!this.shop) {
                this.shopItems = []
            }
        },
        redirectToShop(val) {
            if (val) {
                this.$router.push({
                    name: 'shop.edit',
                    params: {
                        id: val
                    }
                })
            }
        },
        async getShops(str) {
            if (str) {
                this.loadingShops = true
                let params = {}
                params.filter = 'search'
                if (str !== 'undefined') {
                    params.shop = str
                }
                await this.$http
                    .get("admin/shop", {
                        params: params,
                    })
                    .then(res => {
                        this.shopItems = res.body.data
                    })
                    .catch(err => {
                        this.shopItems = []
                    })
                    .finally(end => {
                        this.loadingShops = false
                    })
            }
        },
        async getCoffeeHouses() {

            this.loadingCoffeeHouses = true
            let params = {}
            params.filter = 'all'
            await this.$http
                .get("admin/coffee_house", {
                    params: params,
                })
                .then(res => {
                    this.coffee_houseItems = res.body.data
                })
                .catch(err => {
                    this.coffee_houseItems = []
                })
                .finally(end => {
                    this.loadingCoffeeHouses = false
                })

        },
        async getRestaurants() {

            this.loadingRestaurants = true
            let params = {}
            params.filter = 'all';

            await this.$http
                .get("admin/restaurant", {
                    params: params,
                })
                .then(res => {
                    this.restaurantItems = res.body.data
                })
                .catch(err => {
                    this.restaurantItems = []
                })
                .finally(end => {
                    this.loadingRestaurants = false
                })

        },
        async getFitness() {
            this.loadingFitness = true
            let params = {};
            params.filter = 'all';
            await this.$http
                .get(`admin/fitness`, {
                    params: params,
                })
                .then(res => {
                    this.fitnessItems = res.body.data
                })
                .catch(err => {
                    this.fitnessItems = [];
                    this.$toastr.error(this.$t('failed_to_get_list_fitness'))
                })
                .finally(end => {
                    this.loadingFitness = false
                });
        },
        async getCinema() {
            this.loadingCinema = true
            let params = {};
            params.filter = 'all';
            await this.$http
                .get(`admin/cinema`, {
                    params: params,
                })
                .then(res => {
                    this.cinemaItems = res.body.data
                })
                .catch(err => {
                    this.cinemaItems = [];
                    this.$toastr.error(this.$t('failed_to_get_list_cinema'))
                })
                .finally(end => {
                    this.loadingCinema = false
                });
        },
        async getPark() {
            this.loadingPark = true
            let params = {};
            params.filter = 'all';
            await this.$http
                .get(`admin/park`, {
                    params: params,
                })
                .then(res => {
                    this.parkItems = res.body.data
                })
                .catch(err => {
                    this.parkItems = [];
                    this.$toastr.error(this.$t('failed_to_get_list_parks'))
                })
                .finally(end => {
                    this.loadingPark = false
                });
        },
        async getHotel() {
            this.loadingPark = true
            let params = {};
            params.filter = 'all';
            await this.$http
                .get(`admin/hotel`, {
                    params: params,
                })
                .then(res => {
                    this.hotelItems = res.body.data
                })
                .catch(err => {
                    this.hotelItems = [];
                    this.$toastr.error(this.$t('failed_to_get_list_hotels'))
                })
                .finally(end => {
                    this.loadingPark = false
                });
        },
        async getShoppingCenterService() {
            this.loadingShoppingCenterService = true
            let params = {};
            params.filter = 'all';
            await this.$http
                .get(`admin/shopping_center_service`, {
                    params: params,
                })
                .then(res => {
                    this.shopping_center_serviceItems = res.body.data
                })
                .catch(err => {
                    this.shopping_center_serviceItems = [];
                    this.$toastr.error(this.$t('failed_to_get_list_shopping_center_services'))
                })
                .finally(end => {
                    this.loadingShoppingCenterService = false
                });
        },



        async getCertificate() {
            var _this = this;
            this.progress = 0
            this.loading = true
            let params = {}
            if (this.language) {
                params.language = this.language
            }
            await this.$http
                .get(`admin/certificate/${this.$route.params.id}`, {params: params})
                .then(res => {
                    this.title = res.body.data.title
                    this.main = res.body.data.main
                    this.expiration_to = res.body.data.expiration_to
                    this.expiration_from = res.body.data.expiration_from
                    this.type_company = res.body.data.type_company
                    if(res.body.data.shop && res.body.data.shop.id){
                        this.shop = res.body.data.shop.id
                        this.shopItems = [res.body.data.shop]
                    }
                    this.coffee_house = res.body.data.coffee_house
                    this.restaurant = res.body.data.restaurant
                    this.fitness = res.body.data.fitness
                    this.cinema = res.body.data.cinema
                    this.park = res.body.data.park
                    this.hotel = res.body.data.hotel
                    this.shopping_center_service = res.body.data.shopping_center_service
                    this.type_certificate = res.body.data.type_certificate
                    this.amount = res.body.data.amount
                    this.cashback_percent = res.body.data.cashback_percent
                    if(res.body.data.buy_product && res.body.data.buy_product.id){
                        this.buy_product = res.body.data.buy_product.id
                        this.buyProductItems = [res.body.data.buy_product]
                    }
                    if(res.body.data.get_product && res.body.data.get_product.id){
                        this.get_product = res.body.data.get_product.id
                        this.getProductItems = [res.body.data.get_product]
                    }
                    this.short_description = res.body.data.short_description
                    this.description = res.body.data.description
                    this.active = res.body.data.active
                    this.deleted = res.body.data.deleted
                    this.photo = res.body.data.photo
                    this.images = res.body.data.images

                    this.exist_translations = res.body.data.exist_translations
                    this.all_translations = res.body.data.all_translations
                    this.setTypeCertificate(true)
                })
                .catch(err => {
                    this.$toastr.error(this.$t('failed_to_get_certificate'))
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })
        },
        async saveCertificate() {
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()

            if (this.languages && this.languages[this.tab]) {
                formData.append('language', this.languages[this.tab])
            }

            if (this.title) {
                formData.append('title', this.title)
            }

            if (this.main) {
                formData.append('main', 1)
            }
            else{
                formData.append('main', 0)
            }

            if (this.expiration_to) {
                formData.append('expiration_to', this.expiration_to)
            }
            if (this.expiration_from) {
                formData.append('expiration_from', this.expiration_from)
            }

            if (this.type_company) {
                if (this.type_company.id) {
                    formData.append('type_company', this.type_company.id)
                } else {
                    formData.append('type_company', this.type_company)
                }
            }
            if (this.shop) {
                if (this.shop.id) {
                    formData.append('shop', this.shop.id)
                } else {
                    formData.append('shop', this.shop)
                }
            }
            if (this.coffee_house) {
                if (this.coffee_house.id) {
                    formData.append('coffee_house', this.coffee_house.id)
                } else {
                    formData.append('coffee_house', this.coffee_house)
                }
            }
            if (this.restaurant) {
                if (this.restaurant.id) {
                    formData.append('restaurant', this.restaurant.id)
                } else {
                    formData.append('restaurant', this.restaurant)
                }
            }
            if (this.fitness) {
                if (this.fitness.id) {
                    formData.append('fitness', this.fitness.id)
                } else {
                    formData.append('fitness', this.fitness)
                }
            }
            if (this.cinema) {
                if (this.cinema.id) {
                    formData.append('cinema', this.cinema.id)
                } else {
                    formData.append('cinema', this.cinema)
                }
            }
            if (this.park) {
                if (this.park.id) {
                    formData.append('park', this.park.id)
                } else {
                    formData.append('park', this.park)
                }
            }
            if (this.hotel) {
                if (this.hotel.id) {
                    formData.append('hotel', this.hotel.id)
                } else {
                    formData.append('hotel', this.hotel)
                }
            }
            if (this.shopping_center_service) {
                if (this.shopping_center_service.id) {
                    formData.append('shopping_center_service', this.shopping_center_service.id)
                } else {
                    formData.append('shopping_center_service', this.shopping_center_service)
                }
            }
            if (this.type_certificate) {
                if (this.type_certificate.id) {
                    formData.append('type_certificate', this.type_certificate.id)
                } else {
                    formData.append('type_certificate', this.type_certificate)
                }
            }
            if (this.amount) {
                formData.append('amount', this.amount)
            }
            if (this.cashback_percent) {
                formData.append('cashback_percent', this.cashback_percent)
            }

            if (this.buy_product) {
                if (this.buy_product.id) {
                    formData.append('buy_product', this.buy_product.id)
                } else {
                    formData.append('buy_product', this.buy_product)
                }
            }

            if (this.get_product) {
                if (this.get_product.id) {
                    formData.append('get_product', this.get_product.id)
                } else {
                    formData.append('get_product', this.get_product)
                }
            }
            if (this.short_description) {
                formData.append('short_description', this.short_description)
            }
            if (this.description) {
                formData.append('description', this.description)
            }
            if (this.active) {
                formData.append('active', 1)
            }
            if (this.deleted) {
                formData.append('deleted', 1)
            }


            if (this.photo) {
                if (this.photo.length > 250) {
                    var mimeType = this.getMimeType(this.photo)
                    var blob = this.dataURL64toBlob(this.photo)
                    if (mimeType && blob) {
                        formData.append('photo', blob, mimeType)
                    }
                } else {
                    formData.append('photo', this.photo)
                }
            }

            if (this.images && this.images.length > 0) {
                for (let i in this.images) {
                    if (this.images[i].length > 250) {
                        var mimeTypeImages = await this.getMimeType(this.images[i])
                        var blobImages = await this.dataURL64toBlob(this.images[i])
                        if (mimeTypeImages && blobImages) {
                            formData.append(`images[${i}]`, blobImages, mimeTypeImages)
                        }
                    } else {
                        formData.append(`images[${i}]`, this.images[i])
                    }
                }
            }

            if (this.$route.params.id) {
                // Save
                await this.$http
                    .put(`admin/certificate/${this.$route.params.id}`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('certificate_has_been_updated'))
                        if (res && res.body && res.body.data && res.body.data.exist_translations) {
                            this.exist_translations = res.body.data.exist_translations
                            this.all_translations = res.body.data.all_translations
                        }
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('certificate_has_not_been_updated'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            } else {
                // Add
                await this.$http
                    .post('admin/certificate', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('certificate_has_been_added'))
                        if (res && res.body && res.body.data && res.body.data.id) {
                            this.exist_translations = res.body.data.exist_translations
                            this.all_translations = res.body.data.all_translations
                            this.$router.push({
                                name: 'certificate.edit',
                                params: {
                                    id: res.body.data.id
                                }
                            })
                        } else {
                            this.$router.push({
                                name: 'certificate'
                            })
                        }
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('certificate_has_not_been_added'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            }
        }
    }
}
</script>
